import React, {lazy, memo, Suspense} from 'react';
import AuthLayout from '@/layout/AuthLayout';
import PageLoader from '@/components/PageLoader';

const loadAuthModule = () => import('./authentication');

export const Auth = lazy(loadAuthModule);

const UnauthenticatedApp = () => {
  return <AuthLayout>
    <Suspense fallback={<PageLoader/>}>
      <Auth/>
    </Suspense>
  </AuthLayout>;
};

UnauthenticatedApp.propTypes = {};

UnauthenticatedApp.defaultProps = {};

export default memo(UnauthenticatedApp);
