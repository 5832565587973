/* eslint-disable react-hooks/exhaustive-deps */
import React, {memo, useMemo} from 'react';
import MainRouter from './MainRouter';
import 'antd/es/slider/style';
import {SpaceProvider} from '@dfl/space';
import {ApiClientService, useUser} from '@dofleini/security';
import routes from './routes';

const MainApp = () => {
  const {user} = useUser();
  const config = useMemo(() => {
    return {
      enabled: ApiClientService.getSpace() && ApiClientService.getSpace() !== 'PUBLIC'
    };
  }, [user]);

  return (
    <SpaceProvider config={config}>
      <MainRouter routes={routes}/>
    </SpaceProvider>
  );
};

export default memo(MainApp);
