import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {List, Avatar, Divider} from 'antd';
import renderTitleByType from './components/renderTitleByType';
import renderDescriptionByType from './components/renderDescriptionByType';
import style from './index.less';
import classNames from 'classnames';
import moment from 'moment';

const NotificationItem = ({item}) => {
  return (<>
    <div
      id={`notification-item-${item._id}`}
      className={classNames('py-4 px-6 w-full', {'bg-lightPrimary': !item.read})}>
      <div className="opacity-75">
        {moment(item?.createdAt).fromNow()}
      </div>
      <List.Item className="px-0 m-0 py-2">
        <List.Item.Meta
          id={`notification-item-meta-${item._id}`}
          className={classNames(style.notificationMeta, 'm-0')}
          avatar={<Avatar src={item?.owner?.avatar}/>}
          title={renderTitleByType(item)}
          description={renderDescriptionByType(item)}
        />
      </List.Item>
    </div>
    <Divider className="w-full m-0"/>
  </>);
};

NotificationItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.any,
  isVisible: PropTypes.any,
  updateNotification: PropTypes.func,
};

export default memo(NotificationItem);