import {LAYOUT} from '@dofleini/auth-layout';
import WelcomeLeftSidebar from '@/components/WelcomeLeftSidebar';
// import LoginImageSide  from '../assets/images/login-back.png';


export const authentication = {
  // image: LoginImageSide,
  // background: '#41A636',
  background: '#fff',
  formBackground: '#ffffff',
  rightComponent: <WelcomeLeftSidebar />,
  layout: LAYOUT.SIDE_BY_SIDE,
  path: '/ms-auth',
  showInviteLInk: false,
  showUserName: true,
  showAddress: false,
  //in milliseconds
  tokenExpirationTime: 2 * 60 * 60 * 1000,
  activeOnboarding: true,
  hideOnBoardingFb: true,
  hideOnBoardingGoogle: true,
};
