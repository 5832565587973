/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 18/9/21
 */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '@/assets/images/logo/logo-white.png';
import './index.less';

/** Images */
import dofleini from '@/assets/images/dofleini.svg';
import etecsa from '@/assets/images/etecsa.png';

const WelcomeLeftSidebar = () => {
  const { t } = useTranslation('common');
  return (
    <div className="w-full h-full flex flex-col items-center justify-between wrapper-welcome-left-sidebar">
      <div className="welcome-header">
        <div className="logo mt-2"><img className='h-24 px-2' src={logo} /></div>
      </div>

      <div className="welcome-content w-full flex flex-col items-center justify-center">
        <div className="download-app my-8 flex items-center justify-center">{t('welcomeSidebar.app')}</div>
        <div className="title text-center mb-5">{t('welcomeSidebar.info')}</div>
        <div className="description text-center mb-5">{t('welcomeSidebar.infoSubtext')}</div>
      </div>

      <div className="welcome-footer">
        <div className="trusty-companies w-full flex items-center justify-center">
          <img src={dofleini} alt="Dofleini S.R.L" className={'mr-8'} />
          <img src={etecsa} alt="ETECSA" />
        </div>
      </div>
    </div>
  );
};

WelcomeLeftSidebar.propTypes = {

};

export default memo(WelcomeLeftSidebar);
