import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Loading from '@/components/Loading';
import classNames from 'classnames';

const PageLoader = ({className}) => {
  return (
    <div className={classNames(className, 'flex w-full h-full items-center justify-center')}>
      <Loading/>
    </div>
  );
};

export default memo(PageLoader);

PageLoader.propTypes = {
  className: PropTypes.string
};
