import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {List} from 'antd';
import NotificationItem from '@/components/NotificationDrawer/components/NotificationItem';

// eslint-disable-next-line react/display-name
const createRenderItem = ({updateNotification}) => (item) => {
  return (
    <NotificationItem
      key={`notification-${item?._id}`}
      item={item}
      updateNotification={updateNotification}/>
  );
};

const ListNotification = ({notifications, updateNotification}) => {
  const rowRender = useMemo(() => createRenderItem({updateNotification}), [updateNotification]);

  return <List
    itemLayout="vertical"
    size="large"
    renderItem={rowRender}
    dataSource={notifications}/>;
};

ListNotification.propTypes = {
  notifications: PropTypes.array,
  updateNotification: PropTypes.func,
};

ListNotification.defaultProps = {
  notifications: [],
  updateNotification: () => null
};

export default memo(ListNotification);
