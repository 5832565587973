import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import EntityMenu from '@/components/EntityMenu';
import { ControlOutlined, DeleteOutlined, EditOutlined, EyeOutlined, MailOutlined, RetweetOutlined } from '@ant-design/icons';
import UserStatus from '@/modules/admin/components/UserStatus';
import { USER_STATUS } from '@/modules/admin/contants/user.status';
import { useDialogsContext } from '@/contexts/DialogsContext';
import { RESET_PASSWORD_DIALOG } from '@/modules/admin/containers/ResetPasswordModal';
import { useMutation, useQueryClient } from 'react-query';
import UserApiService from '@/modules/admin/services/UserApiService';
import { Button, message, Space } from 'antd';
import Tooltip from '@/components/Tooltip';
import useCrud, { showConfirm } from '@/hooks/useCrud';
import { useTranslation } from 'react-i18next';
import { DIALOG_NAMESPACE } from '@/components/NewEmailDialog';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserFullName } from '@/utils/userFullName';
import { CACHE_KEY_LIST_USER } from '@/modules/admin/contants/users';
import { useUser } from '@dofleini/security';

const options = (record, currentUser) => [
  {
    icon: <EyeOutlined/>,
    title: 'details',
    action: 'details',

  },
  {
    icon: <EditOutlined/>,
    title: 'edit',
    action: 'edit',
    style: { minWidth: 160 }
  },
  {
    divider: true,
  },
  {
    icon: <MailOutlined/>,
    title: 'sendEmail',
    action: 'sendEmail',
    disabled: record?.id === currentUser?.id,
  },
  {
    icon: <RetweetOutlined/>,
    title: 'resetPassword',
    action: 'resetPassword',
  },
  {
    icon: <ControlOutlined/>,
    title: 'user.changeStatus',
    action: 'changeStatus',
    disabled: record?.id === currentUser?.id,
    menu: [
      {
        title: <UserStatus status={USER_STATUS.ACTIVE} full help/>,
        action: 'changeStatus.active',
        style: { minWidth: 150 }
      },
      {
        title: <UserStatus status={USER_STATUS.LOCK} full help/>,
        action: 'changeStatus.lock',
      },
    ]
  },
  {
    divider: true,
  },
  {
    icon: <DeleteOutlined/>,
    disabled: record?.id === currentUser?.id,
    title: 'remove',
    action: 'remove',
  }
];

const useUserAction = (user, customViewPath, customEditPath) => {
  const { openDialog } = useDialogsContext(RESET_PASSWORD_DIALOG);
  const { openDialog: showEmail } = useDialogsContext(DIALOG_NAMESPACE);
  const { push } = useHistory();
  const { user: loggedUser } = useUser();
  const queryClient = useQueryClient();

  const { t } = useTranslation('admin');
  const { remove } = useCrud(UserApiService, CACHE_KEY_LIST_USER);
  const { mutateAsync: mutate, isLoading } = useMutation(UserApiService.updateStatus);

  const handleChange = useCallback(async (status) => {
    try {
      await mutate({ users: [user._id], status });
      message.success(t('updateStatusSuccess', { status: t(`user.status.${status}`), user: getUserFullName(user) }));
      queryClient.invalidateQueries(CACHE_KEY_LIST_USER);
    } catch (e) {
      message.error('Error');
    }
  }, [mutate, queryClient, t, user]);

  const handleClick = useCallback(({ action }) => {
    switch (action) {
      case 'details': {
        push(customViewPath || `/admin/users/${user?._id}`);
        break;
      }
      case 'edit': {
        push(customEditPath || `/admin/users/${user?._id}/edit`);
        break;
      }
      case 'resetPassword': {
        openDialog(user);
        break;
      }
      case 'sendEmail': {
        showEmail({ users: [user] });
        break;
      }
      case 'changeStatus.active': {
        handleChange(USER_STATUS.ACTIVE);
        break;
      }
      case 'changeStatus.lock': {
        handleChange(USER_STATUS.LOCK);
        break;
      }
      case 'remove': {
        showConfirm(t, user.fullName, () => remove(user._id));
        break;
      }
      default: {
        console.log('missing action handler');
      }
    }
  }, [customEditPath, customViewPath, handleChange, openDialog, push, remove, showEmail, t, user]);

  return {
    options: options(user, loggedUser),
    handleClick,
    isLoading,
    t
  };
};

const UserRowActions = ({ user, forBusiness }) => {

  const {pathname} = useLocation();
  const customViewPath = forBusiness ? `${pathname}/${user.id}` : undefined;
  const customEditPath = forBusiness ? `${pathname}/${user.id}/edit` : undefined;

  const { isLoading, options, handleClick } = useUserAction(user, customViewPath, customEditPath);

  return (
    <EntityMenu options={options} translate={'admin'} onClick={handleClick} isLoading={isLoading}/>
  );
};

// eslint-disable-next-line react/display-name
export const UserHeaderExtraActions = memo(({ data }) => {
  const { isLoading, handleClick, t } = useUserAction(data);
  return (
    <Space>
      <Tooltip parentContainer title={t('sendEmail')} key={'sendEmail'}>
        <Button
          type="text"
          disabled={isLoading}
          onClick={() => handleClick({ action: 'sendEmail' })}
          icon={<MailOutlined/>}/>
      </Tooltip>
      <Tooltip parentContainer title={t('resetPassword')} key={'resetPassword'}>
        <Button
          type="text"
          disabled={isLoading}
          onClick={() => handleClick({ action: 'resetPassword' })}
          icon={<RetweetOutlined/>}/>
      </Tooltip>
    </Space>
  );
});

export default memo(UserRowActions);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Status component in a table cell
 * @param {String} id this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} user this is the row instance
 * @param forBusiness
 * */
export const renderUserActions = (id, user, forBusiness) => {
  return (
    <UserRowActions user={user} forBusiness={forBusiness}/>
  );
};

UserRowActions.propTypes = {
  user: PropTypes.object,
  forBusiness: PropTypes.bool,
};

UserHeaderExtraActions.propTypes = {
  data: PropTypes.object
};
