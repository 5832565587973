import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {createPaginationHook} from '@/utils/createPaginationHook';
import {createContextList} from '@/utils/createContextList';
import {FilterProvider} from '@/contexts/FilterContext';
import {useTranslation} from 'react-i18next';
import {useResponsive} from '@/contexts/ResponsiveContext';
import TableGrid from '@/components/TableGrid';
import {usePageContent} from '@/components/PageContent';
import reduce from 'lodash/reduce';

export const
  ADD_HANDLER_DIALOG = 'handler-dialog';

export const createEntityContainer = ({
  module,
  service,
  translation,
  translationPrefix = '',
  relatedKey = 'id',
  breadcrumbName = 'name',
  route,
  searchPlaceholder,
  pageConfig,
  ...rest
}) => {
  
  const useTablePagination = createPaginationHook(module, service);
  
  const {Provider, useList} = createContextList(useTablePagination, module, pageConfig);
  
  const ContainerList = ({children, top, ...props}) => {
    const {t} = useTranslation(translation || module);
    const {isMobile} = useResponsive();
    
    const {setBreadcrumbCustomMap} = usePageContent();
    
    const onMountData = useCallback((data) => {
      setBreadcrumbCustomMap(reduce(data, (acc, item) => {
        let value;
        switch (typeof breadcrumbName) {
          case 'function':
            value = breadcrumbName(item);
            break;
          case 'string':
          default:
            value = item[breadcrumbName];
            break;
        }
        return {
          ...acc,
          [`${route}/${item[relatedKey]}`]: value
        };
      }, {}));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
      <FilterProvider id={module}>
        <Provider>
          {top}
          <TableGrid
            translation={translation || module}
            scroll={{x: true}}
            addLabel={t(`${translationPrefix}.create`)}
            tableTitle={t(`${translationPrefix}.pageTitle`)}
            useTableData={useList}
            canSelect={!isMobile}
            showHeader={!isMobile}
            onMountData={onMountData}
            translationPrefix={translationPrefix}
            route={route}
            searchPlaceholder={searchPlaceholder && t(searchPlaceholder)}
            {...rest}
            {...props}
          />
          {children}
        </Provider>
      </FilterProvider>
    );
  };
  
  ContainerList.propTypes = {
    children: PropTypes.any,
    top: PropTypes.any
  };
  
  return {
    ContainerList,
    useList,
    ListProvider: Provider,
    useTablePagination
  };
};
