import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import NavMenu from '@/layout/Menu';
import {useUser} from '@dofleini/security';
import UserAvatar from '@/components/UserAvatar';
import {getUserFullName} from '@/utils/userFullName';

const UserActionCollapsable = ({collapsed, menu, ...props}) => {
  const {user} = useUser();
  const fullName = useMemo(() => getUserFullName(user), [user]);

  return <NavMenu
    {...props}
    menu={[{
      menu,
      title: !collapsed && fullName,
      icon: <span className="pr-2"><UserAvatar user={user}/></span>
    }]}
  />;
};

export default memo(UserActionCollapsable);

UserActionCollapsable.propTypes = {
  config: PropTypes.array,
  collapsed: PropTypes.any,
  onClick: PropTypes.func,
  menu: PropTypes.array
};

UserActionCollapsable.defaultProps = {
  config: {}
};