import React, {useMemo, memo} from 'react';
import PropTypes from 'prop-types';
import {
  FileFilled,
  FilePdfFilled,
  FileWordFilled,
  FileExcelFilled,
  FilePptFilled,
  FileMarkdownFilled,
  FileImageFilled,
} from '@ant-design/icons';
import path from 'path';

export const FileIcon = ({file, ...props}) => {

  const extPath = useMemo(() => path.extname(file), [file]);

  return useMemo(() => {
    switch (extPath) {
      case '.pdf':
        return <FilePdfFilled {...props} style={{color: '#ff4d4d'}}/>;
      case '.docx':
      case '.doc':
        return <FileWordFilled {...props} style={{color: '#4299e1'}}/>;
      case '.xlsx':
      case '.csv':
        return <FileExcelFilled {...props} style={{color: '#48bb78'}}/>;
      case '.ppt':
        return <FilePptFilled {...props} style={{color: '#c05621'}}/>;
      case '.md':
        return <FileMarkdownFilled {...props} style={{color: '#5a67d8'}}/>;
      case '.jpg':
      case '.gif':
      case '.png':
        return <FileImageFilled {...props} style={{color: '#63b3ed'}}/>;
      default:
        return <FileFilled {...props} style={{color: '#718096'}}/>;
    }
  }, [extPath, props]);
};

FileIcon.propTypes = {
  file: PropTypes.string,
};

export default memo(FileIcon);