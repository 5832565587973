import React, {memo} from 'react';
import {Button, Layout} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './index.less';
import UserOptions from '@/components/UserOptions';
import Logo from '@/components/Logo';


const popupProps = {
  getPopupContainer: () => document.getElementById('app-top-header'),
  placement: 'bottomRight',
  align: {
    offset: [-2, -2]
  }
};

const ResponsiveHeader = ({className, toggleSider}) => {
  return (
    <Layout.Header id="app-top-header"
      className={classNames('fixed w-full flex-row flex shadow', className, style.header)}>
      <div className={'flex-grow flex items-center'}>
        <div className={'lg:px-3'}>
          <Button type={'text'} size={'large'}
            className={classNames(style.menuButton)}
            onClick={toggleSider}>
            <MenuOutlined/>
          </Button>
        </div>
        <Logo customClass={'mb-0'}/>
      </div>
      <div className={style.userOptions}>
        <UserOptions mode={'horizontal'}
          popupProps={popupProps}
          className={classNames('flex flex-row', style.userOptionToolBar)}/>
      </div>
    </Layout.Header>
  );

};

export default memo(ResponsiveHeader);

ResponsiveHeader.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  toggleSider: PropTypes.func
};
