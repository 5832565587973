/* eslint-disable react-hooks/rules-of-hooks */
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { RouteLoader } from '@dofleini/security';
import { Button, Form, Popconfirm } from 'antd';
import FormBuilder from 'antd-form-builder';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { EntityDetailContent } from '@/components/EntityDetailContent';
import FloatContainer from '@/components/FloatContainer';
import FormSkeleton from '@/components/FormGenerator/FormSkeleton';
import OpenDetail from '@/components/OpenDialog';
import Tooltip from '@/components/Tooltip';
import { DetailsContextProvider, useDetailsContext } from '@/contexts/DetailsContext';
import useCrud from '@/hooks/useCrud';
import { useFormTitle } from '@/hooks/useFormTitle';
import { createReferenceWarning } from '@/utils/createReferenceWarning';

const headerActions = ({
  data,
  t,
  viewMode,
  onSubmit,
  toggleView,
  handleClose,
  onDelete,
  isLoading,
  headerProps = {}
}) => {
  const { extras, canRemove, canEdit, extrasEdit, hideSaveEdit, hideCancelEdit } = headerProps;
  const ExtraOptions = extras;
  const ExtraOptionsEdit = extrasEdit;
  if (viewMode)
    return [
      ExtraOptions && <ExtraOptions key={'detail-extra-actions'} data={data}/>,
      canEdit && <Tooltip parentContainer title={t('edit')} key={'edit'}>
        <Button type="text" icon={<FormOutlined/>} onClick={toggleView} disabled={isLoading}/>
      </Tooltip>,
      canRemove && <Popconfirm
        key={'remove'}
        placement="bottomRight"
        title={t('deleteConfirmation')}
        onConfirm={onDelete}
        okText={t('delete')}
        cancelText={t('cancel')}
      >
        <Tooltip parentContainer title={t('delete')}>
          <Button type="text" icon={<DeleteOutlined/>} loading={isLoading}/>
        </Tooltip>
      </Popconfirm>
    ];
  return (
    [
      ExtraOptionsEdit && <ExtraOptionsEdit
        data={data}
        onSubmit={onSubmit}
        isLoading={isLoading}
        onCancel={handleClose}/>,

      !hideSaveEdit && <Button
        key="submit"
        type="primary"
        onClick={onSubmit}
        loading={isLoading}
        disabled={isLoading}
      >
        {t('save')}
      </Button>,
      !hideCancelEdit && <Button key="back" onClick={handleClose} disabled={isLoading}>
        {t('cancel')}
      </Button>,
    ]
  );
};

export const createEntityDetail = (
  {
    route: defaultRoute, service, name, module, translation, translationPrefix, useFields, useGetOne,
    options = {
      create: true,
      edit: true,
      details: true
    },
    willForceUpdate,
    getOneKey = 'getOne',
    mapPayload = v => v,
    mapResponse = v => v,
    messageReferenceWarningDialog,
    columns,
    entityName,
    ...props
  }) => {
  const dataMock = {};
  const {
    useErrorMiddleware,
    ReferenceWarningDialog,
  } = createReferenceWarning({
    translation,
    service,
    module,
    message: messageReferenceWarningDialog,
  });

  // eslint-disable-next-line react/display-name
  const FormComponent = memo(({ route, headerProps, onMountData }) => {
    const { t } = useTranslation(translation);
    const { push } = useHistory();
    const { create, update, remove, isLoading: isCrudLoading } = useCrud(service, module, getOneKey);
    const { isOpen, closeDialog, payload } = useDetailsContext(name);
    const errorHandler = useErrorMiddleware();
    const { isLoading, data: res } = useGetOne(payload);
    const [form] = Form.useForm();
    const { title, viewMode, toggleView, backToView } = useFormTitle();
    const fields = useFields(viewMode, form, columns);
    const forceUpdate = FormBuilder.useForceUpdate();

    const data = useMemo(() => mapResponse(res?.data), [res]);

    const initValues = data || dataMock;

    const id = initValues?._id;

    const path = route || defaultRoute;

    useEffect(() => {
      (data && onMountData) && onMountData([data]);
    }, [data, onMountData]);

    useEffect(() => {
      setTimeout(() => forceUpdate(), 100);
    }, [forceUpdate, id]);

    const close = useCallback(() => {
      closeDialog();
      setTimeout(() => {
        path && push(path);
        form.resetFields();
      }, 500);
    }, [closeDialog, form, path, push]);

    const onClose = useCallback(() => {
      if (backToView) {
        return toggleView();
      }
      close();
    }, [backToView, close, toggleView]);

    const handleFinish = useCallback(async (values) => {
      if (id) {
        const { data } = await update({ _id: id, ...values });
        form.setFieldsValue(mapResponse(data));
      } else
        await create(values);
      onClose();
    }, [id, create, onClose, update, form]);

    const onFinish = useCallback((values) => {
      const mappedValues = mapPayload(values);

      handleFinish(mappedValues).catch(
        errorHandler({
          onForce: () => handleFinish({ force: true, ...mappedValues }),
          onRecovery: () => close()
        })
      );

    }, [close, errorHandler, handleFinish]);

    const handleDelete = useCallback(async () => {
      if (initValues) {
        await remove(initValues?._id);
        onClose();
      }
    }, [onClose, initValues, remove]);

    const handleClose = useCallback(() => {
      form.setFieldsValue(initValues);
      onClose();
    }, [form, initValues, onClose]);

    useDeepCompareEffect(() => {
      if (!isEmpty(initValues)) {
        form.setFieldsValue(initValues);
      }
    }, [initValues]);

    return (
      <FloatContainer
        {...props}
        visible={isOpen}
        title={t(`${translationPrefix}.${title}`)}
        onClose={onClose}
        footer={headerActions({
          headerProps,
          isLoading: isLoading || isCrudLoading,
          handleClose,
          data: initValues,
          onDelete: handleDelete,
          onSubmit: () => form.submit(),
          entityName: entityName,
          t,
          toggleView,
          viewMode
        })}>
        <Form
          scrollToFirstError
          form={form} onFinish={onFinish} layout={!viewMode ? 'vertical' : 'horizontal'}
          onValuesChange={willForceUpdate ? forceUpdate : false}>
          {isLoading ?
            <FormSkeleton/> :
            <EntityDetailContent viewMode={viewMode} form={form} data={initValues} fields={fields} columns={columns}/>
          }
        </Form>
      </FloatContainer>
    );
  });

  const pathRoute = [];
  if (options?.create) {
    defaultRoute && pathRoute.push(`${defaultRoute}/create`);
  }
  if (options?.details) {
    defaultRoute && pathRoute.push(`${defaultRoute}/:id`);
  }
  if (options?.edit) {
    defaultRoute && pathRoute.push(`${defaultRoute}/:id/edit`);
  }

  const routes = {
    NestedView: {
      path: pathRoute,
      exact: true,
      data: {
        dialog: name
      },
      component: OpenDetail
    }
  };

  // eslint-disable-next-line react/display-name
  const Detail = memo((props) => {
    return <DetailsContextProvider>
      <FormComponent {...props}/>
      <RouteLoader routes={routes} notfoundRedirect={props.route || defaultRoute}/>
      <ReferenceWarningDialog/>
    </DetailsContextProvider>;
  });

  Detail.propTypes = {
    route: PropTypes.any,
  };

  FormComponent.propTypes = {
    headerProps: PropTypes.object,
    route: PropTypes.string,
    onMountData: PropTypes.func,
  };

  FormComponent.defaultProps = {
    headerProps: { canRemove: true, canEdit: true }
  };
  return { FormComponent, Detail };
};

createEntityDetail.propTypes = {
  module: PropTypes.any,
  entityName: PropTypes.any,
  name: PropTypes.any,
  options: PropTypes.object,
  route: PropTypes.any,
  service: PropTypes.any,
  translation: PropTypes.any,
  translationPrefix: PropTypes.any,
  useFields: PropTypes.any,
  useGetOne: PropTypes.any,
  willForceUpdate: PropTypes.bool,
  mapPayload: PropTypes.func,
};

