const source = process.env.REACT_APP_FILE_STORAGE;
const prefixSource = process.env.REACT_APP_PREFIX_FILE_STORAGE || '';

const sourcePath = `${source}/${prefixSource}`;

export const getFromSource = image => {
  if (!image) return '';
  const isSocialOrCDNAvatar =
    (image && image.startsWith('http')) || (image && image.startsWith('https'));
  return isSocialOrCDNAvatar ? image : `${sourcePath}/${image}`;
};
