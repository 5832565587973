import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const SIZE = 18;

const CorrectCheckLabel = ({ text, isCorrect, className, style }) => {

  const color = useMemo(() => isCorrect ? 'darkgreen' : 'darkred', [isCorrect]);

  return <div className={'flex flex-row flex-nowrap items-center w-full'}>
    {isCorrect ? <CheckCircleFilled style={{ color, fontSize: SIZE}}/> : <CloseCircleFilled style={{ color, fontSize: SIZE}}/>}
    <span className={classnames('ml-2', className)} style={{ ...style, color }}>{text}</span>
  </div>;
};

export default memo(CorrectCheckLabel);

CorrectCheckLabel.propTypes = {
  text: PropTypes.string,
  isCorrect: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

CorrectCheckLabel.defaultProps = {
  style: {}
};
