import {useMemo} from 'react';
import {USER_ACTION_TYPE} from '../constants';
import NavMenu from '@/layout/Menu';
import UserActionCollapsable from '@/components/UserOptions/components/UserActionCollapsable';
import UserActionPopup from '@/components/UserOptions/components/UserActionPopup';

export default (config) => useMemo(() => {
  switch (config?.viewType) {
    case USER_ACTION_TYPE.POPUP:
      return UserActionPopup;
    case USER_ACTION_TYPE.COLLAPSABLE:
      return UserActionCollapsable;
    case USER_ACTION_TYPE.LIST:
    default:
      return NavMenu;
  }
}, [config]);
