import {useCallback} from 'react';
import {useAuth} from '@dofleini/security';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {DIALOG_NAMESPACE} from '@/components/NewEmailDialog';
import {NOTIFICATION_DRAWER} from '@/components/NotificationDrawer';
import {NAME} from '@/modules/admin/containers/Profile';
import {useDetailsContext} from '@/contexts/DetailsContext';

export default () => {
  const {logout} = useAuth();
  const {openDialog: showEmail} = useDialogsContext(DIALOG_NAMESPACE);
  const {openDialog: showNotifications} = useDialogsContext(NOTIFICATION_DRAWER);
  const {openDialog: showProfile} = useDetailsContext(NAME);

  return useCallback(({action}) => {
    switch (action) {
      case 'LOGOUT':
        logout();
        break;
      case 'NOTIFICATIONS':
        showNotifications();
        break;
      case 'SENDMAIL':
        showEmail();
        break;
      case 'PROFILE':
        showProfile();
        break;
      default:
        break;
    }
  }, [logout, showNotifications, showEmail, showProfile]);
};