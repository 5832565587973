import React from 'react';
import Email from '@/components/Email/index';
import { Avatar, Tag } from 'antd';
import DatePreview from '@/components/DatePreview';
import CorrectCheckLabel from '@/components/CorrectCheckLabel';
import CountryLabel from '../CountryLabel';

/**
 * This is not a component,
 * it is a function to render the Email component in a table cell
 * */
export const renderEmail = (email, item) => <Email item={item}>{email}</Email>;

/**
 * This is not a component,
 * it is a function to render the Email component in a table cell
 * */
export const renderEmails = (emails, item) => {
  if (Array.isArray(emails)) {
    return emails.map((email, index) => <Email item={item} key={index}>{email}</Email>);
  }

  return 'No Tiene';
};

/**
 * This is not a component,
 * it is a function to render the Avatar component in a table cell
 * */
export const renderAvatar = (avatar) => <Avatar src={avatar} />;

/**
 * This is not a component,
 * it is a function to render the Squared Avatar component in a table cell
 * */
export const renderSquareAvatar = (avatar) => <Avatar shape="square" src={avatar} />;

/**
 * This is not a component,
 * it is a function to render the Date component in a table cell
 * */
export const renderDate = (value) => <DatePreview value={value} format={'L'} />;

export const renderCorrectCheckLabel = ({ text, isCorrect }) => {

  return (
    // eslint-disable-next-line react/prop-types
    <CorrectCheckLabel text={text} isCorrect={isCorrect} />
  );
};

export const renderPhones = (list) => <span>{Array.isArray(list) && list.join(', ')}</span>;


export const renderCountry = (country) => {
  return (<CountryLabel country={country} />);
};


export const renderCategoryBusinessTags = (business_categories) => {

  return (
    <>
      {business_categories?.map(cat => (
        <Tag key={cat}>{cat}</Tag>
      ))}
    </>
  );
};