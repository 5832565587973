import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useUser} from '@dofleini/security';
import {useTranslation} from 'react-i18next';
import {getUserFullName} from '@/utils/userFullName';
import {TagOutlined} from '@ant-design/icons';

export const TitleUpdateTask = ({item}) => {
  const {t} = useTranslation('notifications');
  const {user} = useUser();

  const userName = useMemo(() => {
    return item?.owner?._id === user._id ? t('me') : getUserFullName(item?.owner);
  }, [item.owner, t, user._id]);

  const titleKey = useMemo(() => {
    return item?.owner?._id === user._id ? 'titleMe' : 'title';
  }, [item.owner, user._id]);

  return (
    <span className="text-base">
      {`${userName}`}
      <span className="px-1 font-normal opacity-75">
        {t(`notificationType.${item?.type}.${titleKey}`)}
      </span>
      <span className="text-primary">
        {t(`notificationType.${item?.type}.status.${item?.payload?.body?.status}`)}!
      </span>
    </span>
  );
};

TitleUpdateTask.propTypes = {
  item: PropTypes.object,
};

export const DescriptionUpdateTask = ({item}) => {

  return (<>
    <span className="font-normal text-sm font-semibold italic text-black">
      <TagOutlined className="mr-2 opacity-50"/>
      {item?.payload?.body?.name}
    </span>
  </>);
};

DescriptionUpdateTask.propTypes = {
  item: PropTypes.object,
};