import React, { createContext, useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import PageHeader from '@/components/PageHeader';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useResponsive } from '@/contexts/ResponsiveContext';

const PageContentContext = createContext();

function PageContent ({ children }) {
  const [title, setTitle] = useState();
  const { isMobile } = useResponsive();
  const [breadcrumbCustomMap, setBreadcrumbCustomMap] = useState({});
  const [maxRouteLevel, setMaxRouteLevel] = useState();
  const [isLoading, setIsLoading] = useState();

  return (
    <PageContentContext.Provider value={{
      setBreadcrumbCustomMap,
      setTitle,
      maxRouteLevel,
      setMaxRouteLevel,
      setIsLoading
    }}>
      <Layout>
        <Layout.Content className={'m-1 lg:m-5'}>
          <PageHeader title={title} className={classNames('mb-3 lg:mb-5', { 'truncate': isMobile })}
            customMap={breadcrumbCustomMap} maxRouteLevel={maxRouteLevel} isLoading={isLoading}/>
          {children}
        </Layout.Content>
      </Layout>
    </PageContentContext.Provider>
  );
}

function usePageContent (options = {}) {
  const context = useContext(PageContentContext);
  if (context === undefined) {
    throw new Error('usePageContent must be used within a PageContentProvider');
  }
  const { title } = options;
  const {
    setTitle,
    setBreadcrumbCustomMap,
    setMaxRouteLevel,
    setIsLoading
  } = context;

  useEffect(() => {
    if (title)
      setTitle(title);
    return () => setTitle('');
  }, [setTitle, title]);

  return {
    setTitle,
    setBreadcrumbCustomMap,
    setMaxRouteLevel,
    setIsLoading
  };
}

PageContent.propTypes = {
  children: PropTypes.any
};

export { PageContent, usePageContent };
