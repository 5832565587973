import React, {memo} from 'react';
import {application} from '@/settings';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

const getComponent = (Src, customClass) => {
  if (typeof Src === 'string') {
    return <img className={classNames(customClass, 'mx-0 cursor-pointer max-h-10')} src={Src}
      alt={application.name}/>;
  }
  return <Src className={classNames(customClass, 'mx-0 cursor-pointer max-h-10')}/>;
};

const Logo = ({mini, customClass}) => {
  return <Link to="/">
    <div className={'overflow-hidden'}>
      <div className={'w-32 h-10 flex items-center'}>
        {mini ? getComponent(application.logo.srcMin, customClass) : getComponent(application.logo.src, customClass)}
      </div>
    </div>
  </Link>;
};

Logo.propTypes = {
  customClass: PropTypes.any,
  mini: PropTypes.any
};

export default memo(Logo);
