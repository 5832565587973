import React from 'react';
import MainApp from '@/modules/MainApp';
import {ApiClientService, useUser} from '@dofleini/security';
import UnauthenticatedApp from '@/modules/UnauthenticatedApp';
import {authentication} from '@/settings/authentication';
import OnboardingModule from '@/modules/onboarding';
// import {SPACE_KEY_DOMAIN} from '@/modules/space/constants/space';

ApiClientService.setSpace('ROOT_SPACE');

function App() {
  const {user} = useUser();

  if (user && authentication?.activeOnboarding && !user?.onboardingCompleted)
    return <OnboardingModule/>;

  if (user)
    return <MainApp/>;

  return <UnauthenticatedApp/>;
}

export default App;
