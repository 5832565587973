import React, {memo, Suspense} from 'react';
import {RouteLoader} from '@dofleini/security';
import PageLoader from '@/components/PageLoader';
import PropTypes from 'prop-types';

const MainRouter = ({routes}) => {
  return (
    <Suspense fallback={<PageLoader className={'h-screen'}/>}>
      <RouteLoader routes={routes} notfoundRedirect={'/'}/>
    </Suspense>
  );
};

export default memo(MainRouter);

MainRouter.propTypes = {
  routes: PropTypes.object
};
