import React from 'react';
import {ExportOutlined, UserOutlined} from '@ant-design/icons';
import {useHandleClick} from '@/components/UserOptions/hooks';
import {USER_ACTION_TYPE} from '@/components/UserOptions/constants';
import {
  RiBriefcase4Line,
  RiCalendarEventLine,
  RiCommunityLine,
  RiContactsBookLine,
  RiFileList3Line,
  RiHome3Line,
  RiSettings3Line,
} from 'react-icons/ri';
import {AiOutlineNotification} from 'react-icons/ai';

const iconsProps = {
  className: 'antd-icon w-6 h-6'
};

/**
 * Navigation define all the routes and link that
 * will be render in the sidebar or navbar(depending of the layout)
 *
 * It can be an
 * {Array} Use array when yo have group sections, like Main, Admin , etc
 * Ex: [{title:'name', menu:[...],}, ....]
 *
 * {Object} Use object when yo don't have section , it is just one,
 * Ex: {menu: [.....]}
 * */
export const navigation = {
  menu: [
    {
      title: 'home',
      exact: 'true',
      link: '/',
      icon: <RiHome3Line {...iconsProps}/>
    },
    {
      title: '/business',
      link: '/business',
      permissions: ['BUSINESS'],
      icon: <RiCommunityLine {...iconsProps}/>,
      menu: [
        {
          title: '/business/directory',
          exact: 'true',
          link: '/business/directory',
          permissions: ['BUSINESS'],
        },
        {
          title: '/business/owner-request',
          exact: 'true',
          link: '/business/owner-request',
          permissions: ['OWNER_REQUEST'],
        },
        {
          title: '/business/nit-finder',
          exact: 'true',
          link: '/business/nit-finder',
          permissions: ['BUSINESS'],
        },
      ]
    },
    {
      title: '/request',
      link: '/request',
      permissions: ['BUSINESS'],
      icon: <RiCommunityLine {...iconsProps}/>,
      menu: [       
        {
          title: '/request/foreign-company-request',
          exact: 'true',
          link: '/request/foreign-company-request',
          permissions: ['BUSINESS'],
        },
      ]
    },
    {
      exact: 'true',
      link: '/biddings',
      //disabled: true,
      permissions: ['BIDDING'],
      icon: <RiFileList3Line {...iconsProps}/>
    },
    {
      title: '/clients',
      exact: 'true',
      link: '/clients',
      permissions: ['USER:READ', 'SECURITY:READ'],
      icon: <RiContactsBookLine {...iconsProps}/>,
      menu: [
        {
          exact: 'true',
          link: '/clients/users',
          permissions: ['USER:READ'],
        },
        {
          exact: 'true',
          link: '/clients/contacts-messages',
          disabled: true,
          permissions: ['SUPPORT'],
        },
        {
          exact: 'true',
          link: '/clients/subscriptions',
          disabled: true,
          permissions: ['SUBSCRIPTION'],
        }
      ]
    },
    {
      title: '/admin',
      exact: 'true',
      link: '/admin',
      permissions: ['USER:READ', 'PAGE:WRITE', 'BILLING.READ'],
      icon: <RiSettings3Line {...iconsProps}/>,
      menu: [
        {
          link: '/admin/users',
          permissions: ['USER.READ']
        },
        {
          link: '/cms/admin',
          permissions: ['PAGE:WRITE']
        },
        {
          link: '/admin/billing',
          disabled: true,
          permissions: ['BILLING:READ']
        },
      ]
    },
    {
      exact: 'true',
      link: '/job-offers',
      disabled: true,
      permissions: ['JOBS'],
      icon: <RiBriefcase4Line {...iconsProps}/>
    },
    {
      exact: 'true',
      link: '/events',
      permissions: ['EVENTS'],
      disabled: true,
      icon: <RiCalendarEventLine  {...iconsProps}/>
    },
    {
      link: '/ads',
      permissions: ['ADS'],
      disabled: true,
      icon: <AiOutlineNotification  {...iconsProps}/>
    },
  ]
};

export const userOptions = {
  options: [],
  userActions: {
    viewType: USER_ACTION_TYPE.POPUP,
    showInfo: true,
    options: [
      {
        divider: true
      },
      {
        title: 'profile',
        action: 'PROFILE',
        icon: <UserOutlined/>,
      },
      {
        divider: true
      },
      {
        title: 'logout',
        action: 'LOGOUT',
        icon: <ExportOutlined/>,
      }
    ],
  },
  useHandleClick
};
