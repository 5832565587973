import PropTypes from 'prop-types';
import React, {memo, useCallback} from 'react';
import {Avatar, Form, Typography} from 'antd';
import cn from 'classnames';
import {getFromSource} from '@/utils/getImages';
import UploadImage from '@/components/UploadImage/UploadImage';

const {Text, Title} = Typography;

const AvatarSection = ({editing, value, onChange, className, helperText, onChangeFullValue, ...props}) => {

  const handleChange = useCallback((data) => {
    if (typeof data === 'object') {
      onChange(data?.thumb || data?.image);
      onChangeFullValue(data);
    } else {
      onChange(data);
    }
  }, [onChange, onChangeFullValue]);

  return (
    <>
      {
        helperText && <Text className={'-mt-2 mb-2 block'} type={'secondary'}>{helperText}</Text>
      }
      {editing ?
        <UploadImage onChange={handleChange} value={getFromSource(value)}/>
        :
        <div className={cn(['flex items-center', className])}>
          <Avatar src={getFromSource(value?.avatar)} size={90} {...props}/>
          <div className={'ml-2'}>
            <Title level={4}>{value?.fullName}</Title>
            <Text type={'secondary'}>{value?.username}</Text>
          </div>
        </div>
      }
    </>
  );

};
export default memo(AvatarSection);

AvatarSection.propTypes = {
  value: PropTypes.any,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeFullValue: PropTypes.func,
  className: PropTypes.string,
  helperText: PropTypes.string
};

AvatarSection.defaultProps = {
  onChangeFullValue: v => v,
};

// eslint-disable-next-line react/prop-types
export const renderAvatarSection = ({formItemProps, form, field}) => {

  const onChange = (value = {}) => {
    const {thumb: avatar, image: avatarOriginal} = value;
    // eslint-disable-next-line react/prop-types
    form.setFieldsValue({
      avatar,
      avatarOriginal,
    });
  };

  return (
    // eslint-disable-next-line react/prop-types
    <Form.Item {...formItemProps} required={field?.required}>
      <AvatarSection onChangeFullValue={onChange} editing/>
    </Form.Item>
  );
};
