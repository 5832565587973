import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Table} from 'antd';
import {getloadingArray} from '@/utils/loadingData';
import PropTypes from 'prop-types';
import style from './index.less';
import classNames from 'classnames';
import {InfoCircleFilled} from '@ant-design/icons';
import {useTableGrid} from '@/contexts/tableGridContact';
import {useTranslation} from 'react-i18next';
import map from 'lodash/map';
import filter from 'lodash/filter';

const LOADING_DATA = getloadingArray(10);

const TableData = ({tableHook, canSelect, selectedActions, onMountData, rowKey, ...props}) => {
  const { setPage, totalPages, isFetching, data, setSize, page, size: pageSize } = tableHook();
  const [selectedRowsData, setSelectedRows] = useState([]);
  const {t} = useTranslation('table');

  const syncSelectedData = useCallback(() => {
    const validIds = map(data, ({_id}) => _id);

    const [selectedIds, selectedRows] = selectedRowsData;

    setSelectedRows([
      filter(selectedIds, _id => validIds.includes(_id)),
      filter(selectedRows, ({_id}) => validIds.includes(_id)),
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    onMountData(data);
    syncSelectedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {visibleColumns, size} = useTableGrid();

  /**
   * @param {Array} list of selected rows key
   * @param {Array} list of selected full entity items
   * */
  const onChangeRowsSelected = useCallback((...args) => setSelectedRows(args), []);

  const selectedRowKeys = useMemo(() => selectedRowsData[0] || [], [selectedRowsData]);
  const selectedRows = useMemo(() => selectedRowsData[1] || [], [selectedRowsData]);

  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    onChange: onChangeRowsSelected
  }), [onChangeRowsSelected, selectedRowKeys]);

  const paginationConfig = useMemo(() => {
    return {
      pageSize,
      hideOnSinglePage: true,
      onChange: setPage,
      onShowSizeChange: (index, value) => setSize(value),
      total: totalPages,
      size: 'default',
      current: page
    };
  }, [pageSize, setPage, totalPages, page, setSize]);

  return (
    <div className={style.tableContainer}>
      {
        !!selectedRowKeys.length &&
        <div className={classNames(['hidden md:flex items-center justify-between mb-3', style.selectionActions])}>
          <div className={'selected'}>
            <InfoCircleFilled/>
            {selectedRowKeys.length}
            <span className={'lg:hidden'}>{t('selectedElementsXs')}</span>
            <span className={'hidden lg:inline-block'}>{t('selectedElements')}</span>
          </div>
          <div className={'actions flex'}>
            <Button type="text" onClick={() => setSelectedRows([])}>{t('deselect')}</Button>
            {selectedActions({selectedRows, selectedRowKeys})}
          </div>
        </div>
      }
      <Table
        {...props}
        size={size}
        rowSelection={canSelect ? rowSelection : false}
        columns={visibleColumns}
        rowKey={rowKey}
        pagination={paginationConfig}
        dataSource={!data && isFetching ? LOADING_DATA : data}
      />
    </div>
  );
};

export default memo(TableData);

TableData.propTypes = {
  tableHook: PropTypes.func,
  canSelect: PropTypes.bool,
  selectedActions: PropTypes.func,
  onMountData: PropTypes.func,
  rowKey: PropTypes.string,
};

TableData.defaultProps = {
  onMountData: v => v,
  rowKey: '_id'
};
