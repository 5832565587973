import { NameRuler, PasswordRuler, PhoneCubaNumberRuler } from '@dofleini/validator';
import { authentication } from '@/settings';
import { renderAvatarSection } from '@/modules/admin/components/UserDetailContent/AvatarSection';
import renderAvatarViewWidget from '@/components/UserAvatar/renderAvatarViewWidget';
import createFormFieldset from '@/utils/createFormFieldset';
import { renderUserRoles } from '@/modules/admin/components/UserRoles';
import { renderFieldPassword } from '@/modules/admin/components/FieldPassword';
import { createSearchFieldRemote } from '@/utils/createSearchFieldRemote';
import { useSearchRol } from '@/modules/admin/hooks/useSearchRol';
import createClearRenderView from '@/utils/createClearRenderView';

export const baseFields = [
  {
    key: 'avatar',
    colSpan: 2,
    onlyView: true,
    renderView: renderAvatarViewWidget
  },
  {
    key: 'avatar',
    colSpan: 2,
    // eslint-disable-next-line react/display-name
    render: renderAvatarSection,
    onlyEdit: true,
    label: 'user.form.avatar.placeholder'
  },
  {
    key: 'firstName',
    colSpan: 1,
    label: 'user.form.name.placeholder',
    onlyEdit: true,
    required: true,
    rules: [NameRuler],
  },
  {
    key: 'lastName',
    colSpan: 1,
    label: 'user.form.lastName.placeholder',
    onlyEdit: true
    
  },
  {
    key: 'phone',
    colSpan: 2,
    renderView: createClearRenderView(),
    label: 'user.form.phone.placeholder',
    rules: [PhoneCubaNumberRuler]
  },
  {
    key: 'email',
    colSpan: 2,
    label: 'user.form.email.placeholder',
    renderView: createClearRenderView(),
    rules: [{ required: true }, { type: 'email' }],
  },
];

const fields = [
  ...baseFields,
  {
    key: 'security',
    colSpan: 2,
    render: createFormFieldset({ title: 'user.form.security', translation: 'admin' }),
  },
  {
    key: 'roles',
    colSpan: 2,
    label: 'user.form.roles',
    widget: createSearchFieldRemote({
      usePagination: useSearchRol,
      mode: 'multiple',
      fullEntity: true
    }),
    initialValue: [],
    renderView: renderUserRoles
  },
  {
    key: 'createdAt',
    label: 'registerAt',
    type: 'date',
    onlyView: true
  },
  {
    key: 'password',
    label: 'setNewPassword',
    colSpan: 2,
    onlyEdit: true,
    widget: renderFieldPassword,
    rules: [PasswordRuler]
  },
];

if (authentication.showAddress) {
  fields.push(
    {
      key: 'address',
      colSpan: 2,
      label: 'USER.FORM.ADDRESS.PLACEHOLDER'
    }
  );
}

export { fields };
