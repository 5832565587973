import React from 'react';
import ReactDOM from 'react-dom';
import '@/assets/tailwindcss/styles.css';
import '@/assets/less/index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import MainProvider from '@/contexts/MainProvider';
import * as dotenv from 'dotenv';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

dotenv.config();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || 'http://2af7d90f79b344a5b81ebcdb9ef328ad@10.0.0.17:9000/6',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <MainProvider><App /></MainProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
