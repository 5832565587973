import React, {Suspense} from 'react';
import {RouteLoader} from '@dofleini/security';
import routes from './routes.constant';
import PageLoader from '@/components/PageLoader';

const ModuleRoutes = () => {
  return (
    <Suspense fallback={<PageLoader/>}>
      <RouteLoader routes={routes} notfoundRedirect={'/onboarding'}/>
    </Suspense>
  );
};

ModuleRoutes.propTypes = {};

ModuleRoutes.defaultProps = {};

export default ModuleRoutes;
