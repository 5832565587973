import LogoMin  from '@/assets/images/logo/logo-mini.png';
import Logo  from '@/assets/images/logo/logo-normal.png';

export const application = {
  name: 'Guía',
  logo: {
    src: Logo,
    srcMin: LogoMin
  }
};
