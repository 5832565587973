import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { getFromSource } from '@/utils/getImages';

export const EMPTY_AVATAR_VIEW = {
  INITIALS: 'INITIALS',
  DEFAULT: 'DEFAULT'
};

const UserAvatar = ({ user, src, emptyAvatarView, ...props }) => {
  const userAvatar = useMemo(() => src || user?.avatar || user?.avatarOriginal, [src, user]);
  
  return (
    <Avatar
      src={getFromSource(userAvatar)}
      icon={!userAvatar && emptyAvatarView === EMPTY_AVATAR_VIEW.DEFAULT && <UserOutlined/>}
      size="small"
      {...props}
    >
      {!userAvatar && emptyAvatarView === EMPTY_AVATAR_VIEW.INITIALS && user?.firstName}
    </Avatar>
  );
};

export default memo(UserAvatar);

UserAvatar.propTypes = {
  user: PropTypes.object,
  src: PropTypes.string,
  emptyAvatarView: PropTypes.string,
};

UserAvatar.defaultProps = {
  user: {},
  emptyAvatarView: EMPTY_AVATAR_VIEW.DEFAULT,
};
