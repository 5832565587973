import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {authentication} from '@/settings';
import {AuthLayout} from '@dofleini/auth-layout';

const AuthenticationAppLayout = ({children}) => {
  return (
    <AuthLayout {...authentication} rightClass={'place-item-center'}>
      <div>
        {children}
      </div>
    </AuthLayout>
  );
};
AuthenticationAppLayout.propTypes = {
  children: PropTypes.any,
};

export default memo(AuthenticationAppLayout);
