import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { PageHeader as AntPageHeader, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import style from './index.less';
import { useResponsive } from '@/contexts/ResponsiveContext';

// eslint-disable-next-line react/prop-types
const itemRender = ({ path, breadcrumbName }) => (<Link to={path}>
  {breadcrumbName}
</Link>);

const PageHeader = ({ title, customMap, className, maxRouteLevel, isLoading, ...props }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation('navigation');
  const { isMobile } = useResponsive();
  const pathSnippets = pathname.split('/').slice(0, maxRouteLevel).filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    let title = '';
    if (url.endsWith('create')) {
      title = 'create';
    }
    if (url.endsWith('edit')) {
      title = 'edit';
    }
    return {
      path: url,
      breadcrumbName: (customMap && customMap[url]) || t(title || url),
    };
  });

  const routes = [
    {
      path: '/',
      breadcrumbName: t('home')
    }
  ].concat(extraBreadcrumbItems);

  // const pageTitle = title || routes[routes.length - 1].breadcrumbName;

  return (
    <div className={className}>
      {
        isLoading ? <Skeleton.Button className={'w-full'} active={true}/>
          : <AntPageHeader
            ghost={false}
            className={classNames({ [style.noTitle]: !title }, { 'truncate': isMobile })}
            title={title}
            breadcrumb={{ routes, itemRender }}
            {...props}
          />
      }
    </div>
  );

};

export default memo(PageHeader);

PageHeader.propTypes = {
  className: PropTypes.string,
  customMap: PropTypes.object,
  title: PropTypes.string,
  maxRouteLevel: PropTypes.number,
  isLoading: PropTypes.bool,
};
