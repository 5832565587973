import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


const CountryLabel = ({ country }) => {
  const { t } = useTranslation('countries');

  const label = useMemo(() => t(`countries.${country}`) || country, [country, t]);
  return (
    <>{label}</>
  );

};

CountryLabel.propTypes = {
  country: PropTypes.string
};

export default memo(CountryLabel);