import {useEffect, useMemo} from 'react';
import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';
import {useQuery, useQueryClient} from 'react-query';
import {logout} from '@/modules/authentication/services/AuthClient';

const USER_PROFILE = '__user_profile__';

export const useMe = () => {
  const queryClient = useQueryClient();
  const path = `${authentication.path}/api/users/me`;

  const user = useMemo(() => {
    const user = localStorage.getItem(USER_PROFILE);
    if (user)
      try {
        return JSON.parse(user);
      } catch (e) {
        //ignoring
      }
  }, []);

  const {isLoading, error, data, refetch, isStale} = useQuery('user-me', async () => {
    if (!ApiClientService.getToken()) return Promise.reject({authenticatedError: true});
    const {data} = await ApiClientService.get(path);
    localStorage.setItem(USER_PROFILE, JSON.stringify(data));
    return (data);

  }, {
    retry: 2, // Will retry failed requests 10 times before displaying an error
    refetchOnWindowFocus: false
  });

  const status = error?.status;
  useEffect(() => {
    if (status === 401) {
      logout().then(() => {
        queryClient.invalidateQueries('user-me');
      });
    }
  }, [queryClient, status]);

  return {
    data: error?.status || error?.authenticatedError ? undefined : data || user,
    isLoading, error, refetch, isStale
  };
};

