import React, {memo, useState, useCallback} from 'react';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {Drawer} from 'antd';
import {useTranslation} from 'react-i18next';
import NotificationsList from '@/components/NotificationDrawer/components/NotificationsList';
import classNames from 'classnames';
import {useResponsive} from '@/contexts/ResponsiveContext';
import HeaderNotification from '@/components/NotificationDrawer/components/HeaderNotification';
import FooterNotification from '@/components/NotificationDrawer/components/FooterNotification';
import style from './index.less';
import mockNotifications from './mockData.json';

export const NOTIFICATION_DRAWER = 'notification-drawer';

const NotificationDrawer = () => {
  const [notifications, setNotifications] = useState(mockNotifications);
  const {isMobile} = useResponsive();

  const updateNotification = useCallback(({id, ...rest}) => {
    setNotifications(notifications.map(notification => notification.id === id ? {...notification, ...rest} : notification));
  }, [notifications]);

  const {t} = useTranslation('notifications');
  const {isOpen, closeDialog} = useDialogsContext(NOTIFICATION_DRAWER);

  return <Drawer
    visible={isOpen}
    className={classNames(style.drawerBody, 'absolute z-30')}
    title={<HeaderNotification t={t} onClose={closeDialog}/>}
    footer={<FooterNotification t={t} clearAll={() => null} markAsRead={() => null}/>}
    placement="left"
    onClose={closeDialog}
    getContainer={false}
    closable={false}
    width={isMobile ? '100%' : '500px'}
  >
    <NotificationsList notifications={notifications} updateNotification={updateNotification}/>
  </Drawer>;
};

NotificationDrawer.propTypes = {};

export default memo(NotificationDrawer);