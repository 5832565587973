import { FilterFactory } from '@dofleini/query-builder';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useState } from 'react';

const FilterContext = createContext();

function FilterProvider ({ id, children, defaultFilter, ...props }) {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(defaultFilter);
  const [globalFilter, setGlobalFilter] = useState(defaultFilter);
  const [isOpen, setOpen] = useState(true);

  const closeFilter = useCallback(() => {
    setOpen(false);
  }, []);

  const openFilter = useCallback(() => {
    setOpen(true);
  }, []);

  const toggleFilter = useCallback(() => {
    setOpen(prevState => !prevState);
  }, []);

  const joinGlobalAndFilters = useCallback((filtersValue) => {
    setFilters(FilterFactory.add(filtersValue, globalFilter).toQuery());
  }, [globalFilter]);

  if (useFilter(id).id === id) {
    return children;
  }

  return (
    <FilterContext.Provider value={{
      id,
      isOpen,
      closeFilter,
      openFilter,
      toggleFilter,
      filter: filters,
      globalFilter,
      setFilter: joinGlobalAndFilters,
      setGlobalFilter,
      search,
      setSearch,
      defaultFilter
    }} {...props}>
      {children}
    </FilterContext.Provider>
  );
}

function useFilter() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    return {};
  }
  const {
    id,
    isOpen,
    closeFilter,
    openFilter,
    toggleFilter,
    globalFilter,
    setGlobalFilter,
    filter,
    setFilter,
    search,
    setSearch
  } = context;

  return {
    id,
    isOpen,
    closeFilter,
    openFilter,
    toggleFilter,
    globalFilter,
    setGlobalFilter,
    filter,
    setFilter,
    search,
    setSearch
  };
}

FilterProvider.propTypes = {
  children: PropTypes.any,
  defaultFilter: PropTypes.any,
  id: PropTypes.string
};

export {FilterProvider, useFilter};

