import {ApiClientService, EntityApiService} from '@dofleini/security';
import {authentication} from '@/settings';

class UserApiService extends EntityApiService {
  updateStatus = (params) => {
    return ApiClientService.post(this.getPath('/status'), params);
  };

  searchRootUsers = (params) => {
    return ApiClientService.post(this.getPath('/search'), params, {headers: {'space-root': 'true'}});
  };

  passwordReset = (params) => {
    return ApiClientService.post(this.getPath('/password-reset'), params);
  };

}

export default new UserApiService(`${authentication.path}/api/users`);
