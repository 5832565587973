import AdminModule from '@/modules/admin';
import ClientModule from '@/modules/clients';
import BusinessModule from '@/modules/business';
import DashboardModule from '@/modules/dashboards';
import BiddingModule from '@/modules/bidding';
import RequestModule from '@/modules/request';

export default {
  Home: {
    exact: true,
    path: '/',
    component: DashboardModule
  },
  User: {
    exact: false,
    path: '/admin',
    component: AdminModule
  },
  Clients: {
    exact: false,
    path: '/clients',
    component: ClientModule
  },
  Business: {
    exact: false,
    path: '/business',
    component: BusinessModule
  },
  Bidding: {
    exact: false,
    path: '/biddings',
    component: BiddingModule
  },
  Request: {
    exact: false,
    path: '/request',
    component: RequestModule
  },
};
