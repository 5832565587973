import {CMSAdmin} from '@/modules/cms';
import ModulesApp from '@/modules/ModuleApp';

export default {
  CMS: {
    exact: false,
    path: '/cms/admin',
    permissions: ['PAGE:WRITE'],
    component: CMSAdmin
  },
  Actors: {
    path: '/',
    component: ModulesApp
  },
};
