/* eslint-disable react-hooks/exhaustive-deps */
import React, {memo} from 'react';
import MainRouter from './MainRouter';
import MainLayout from '@/layout/MainLayout';
import 'antd/es/slider/style';
import NewEmailDialog from '@/components/NewEmailDialog';
import routes from './routes-modules';

const MainApp = () => {
  return (
    <MainLayout>
      <MainRouter routes={routes}/>
      <NewEmailDialog/>
    </MainLayout>
  );
};

export default memo(MainApp);
